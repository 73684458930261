// Generated by Framer (677c5d1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["Z7WLaoAgi", "NH4el8sw1"];

const serializationHash = "framer-1yclF"

const variantClassNames = {NH4el8sw1: "framer-v-oie94o", Z7WLaoAgi: "framer-v-12e2hzf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.2, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Active: "NH4el8sw1", Inactive: "Z7WLaoAgi"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Z7WLaoAgi"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Z7WLaoAgi", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-12e2hzf", className, classNames)} data-framer-name={"Inactive"} layoutDependency={layoutDependency} layoutId={"Z7WLaoAgi"} ref={refBinding} style={{backgroundColor: "rgb(85, 64, 247)", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%", ...style}} {...addPropertyOverrides({NH4el8sw1: {"data-framer-name": "Active"}}, baseVariant, gestureVariant)}/></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-1yclF.framer-h4ara2, .framer-1yclF .framer-h4ara2 { display: block; }", ".framer-1yclF.framer-12e2hzf { height: 7px; position: relative; width: 7px; }", ".framer-1yclF.framer-v-oie94o.framer-12e2hzf { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 7px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 7
 * @framerIntrinsicWidth 7
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"NH4el8sw1":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerNokP38Cpg: React.ComponentType<Props> = withCSS(Component, css, "framer-1yclF") as typeof Component;
export default FramerNokP38Cpg;

FramerNokP38Cpg.displayName = "Timeline Indicator";

FramerNokP38Cpg.defaultProps = {height: 7, width: 7};

addPropertyControls(FramerNokP38Cpg, {variant: {options: ["Z7WLaoAgi", "NH4el8sw1"], optionTitles: ["Inactive", "Active"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerNokP38Cpg, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})